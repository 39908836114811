'use client';

function ShopifyChat() {
	const isCrawlerBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
	if (isCrawlerBot) return null;

	return (
		<script
			src="https://cdn.shopify.com/extensions/17dc8132-f940-4023-aac5-3a8bd735310a/inbox-934/assets/shopifyChatV1Widget.js?button_color=%23003fb9&amp;sc=%23FFFFFF&amp;tc=%23232323&amp;i=chat_bubble&amp;t=no_text&amp;p=bottom_right&amp;vp=lowest&amp;shop_id=n5ve3y-V-f0KO7neNeNlmpuzD7WaFiZFXrQ1HHqvFAE&amp;shop=gradivohr.myshopify.com"
			type="module"
			defer
			async
		/>
	);
}

export default ShopifyChat;
